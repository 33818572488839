<template>
  <div class="form-stepper form-stepper-campaign">
    <v-form ref="form" v-model="valid" class="form pa-6" @submit.prevent>
      <p>{{ $t("CAMPAIGN.STATS_TITLE") }}</p>

      <v-checkbox
        class="mt-0 pt-0"
        v-model="define_ga4_params"
        :label="$t('CAMPAIGN.FIELD_GA4_PARAMS_TITLE')"
        :placeholder="$t('CAMPAIGN.FIELD_GA4_PARAMS_TITLE')"
        outlined
      />
      <div class="pa-0" v-if="define_ga4_params">
        <v-row class="mb-0">
          <v-col cols="4">
            <v-text-field
              dense
              v-model="ga4_property_id"
              autofocus
              :rules="[$rules.maxlength(255)]"
              :label="$t('CAMPAIGN.FIELD_GA4_PARAMS_PROPERTY_ID')"
              :placeholder="$t('CAMPAIGN.FIELD_GA4_PARAMS_PROPERTY_ID_PLACEHOLDER')"
              @keyup.enter="false"
              outlined
            />
            <v-text-field
              dense
              v-model="ga4_conversion_event_name"
              :rules="[$rules.maxlength(255)]"
              :label="$t('CAMPAIGN.FIELD_GA4_PARAMS_CONVERSION_EVENT_NAME')"
              :placeholder="$t('CAMPAIGN.FIELD_GA4_PARAMS_CONVERSION_EVENT_NAME_PLACEHOLDER')"
              @keyup.enter="false"
              outlined
            />
          </v-col>
        </v-row>
      </div>

    </v-form>

    <v-divider />
    <v-toolbar color="transparent" flat height="70px" class="form-footer">
      <v-spacer />
      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t("COMMON.CONTINUE") }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<style>

.thumb-channel {
  position: relative;
  padding: 10px;
  border: solid 1px rgba(0,0,0,.3);
  margin: 0 5px 5px 0;
  border-radius: 3px;
}

.thumb-channel-category {
  position: absolute;
  right:0;
  bottom:0;
  text-transform: uppercase;
  padding: 0 5px;
  position: absolute;
  font-size: 10px;
}

</style>


<script>

export default {
  name: "FormCampaignStepperIntro",
  components: {

  },
  data: (_this) => ({
    loading: false,
    valid: false,
    define_ga4_params: false,
    ga4_property_id: null,
    ga4_conversion_event_name: null,
  }),
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    // mode(n) {
    //   if (n) {
    //     this.methods = [...n.methods];
    //     this.types = [...n.types];
    //   } else {
    //     this.methods = [];
    //     this.types = [];
    //   }
    //   this.loadChannelSubscriptionItems();
    // },
    channel_categories () {
      this.loadChannelSubscriptionItems()
    },
    values(n) {
      this.init();
    },
  },
  mounted() {
    this.mode = this.$i18n.t("CAMPAIGN.MODE_ITEMS")[0]
    this.init()
  },
  methods: {
    getImage(item, size = "original") {
      const url = `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.channel.controller_name.toLowerCase()}.png`
    },

    loadChannelSubscriptionItems() {
      this.loading = true;
      this.$services.api_programmatic.channel_subscription
        .search({
          where: {
            status: [1],
            channel: {
              required: true,
              category: this.channel_categories,
            },
          },
        })
        .then((response) => {
          if (response.data) {
            this.channelSubscriptionItems = response.data;
          } else {
            this.channelSubscriptionItems = []
          }
        })
        .finally(() => {
          this.loading = false
        });
    },

    init() {
      Object.keys(this.values).forEach((key) => {
        if (
          typeof this.values[key] !== "undefined" &&
          typeof this[key] !== "undefined"
        ) {
          this[key] = this.values[key]
        }
      });
    },
    validate() {
      if (!this.$refs.form.validate()) {
        this.$emit("validation", false)
        return;
      }

      const data = {};
      data.ga4_params = {
        define_ga4_params: !!this.define_ga4_params,
        ga4_property_id: this.ga4_property_id,
        ga4_conversion_event_name: this.ga4_conversion_event_name,
      }
      this.$emit("childData", data)
      this.$emit("validation", true)
      this.$emit("next")
    },
  },
};
</script>

<style scoped>
</style>
